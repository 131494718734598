import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseAccountVerify, VerificationOptions } from './useAccountVerify.type'
import { useState } from 'react'

export default (): UseAccountVerify => {
   const [verificationOptions, setVerificationOptions] =
      useState<VerificationOptions | null>(null)
   const request = useAPI()

   const { data, isLoading } = useQuery(
      'account-registration-email-verify',
      (): Promise<AxiosResponse> => verifyNewAccountEmail(verificationOptions),
      { enabled: !!verificationOptions }
   )

   async function verifyNewAccountEmail(
      verificationOptions
   ): Promise<AxiosResponse<any>> {
      try {
         return await request.post(
            PATH.REGISTER.ACCOUNT_VERIFY,
            verificationOptions
         )
      } catch (error) {
         console.log(error)
         return error
      }
   }

   return {
      isLoading,
      validResponse: data?.status === 200 ? true : false,
      setVerificationOptions,
      verificationOptions,
   }
}
